import { Link } from "gatsby";
import React, { ReactElement, useState } from "react";
import menuIcon from "../../images/menu-icon.svg";
const styles = require("./index.module.css");
export interface IMenuItem {
  title: string;
  link: string;
  items?: IMenuItem[]
}

export interface MainMenuProps {
  mainMenu: IMenuItem[];
}

export default function MainMenu({
  mainMenu,
}: MainMenuProps): ReactElement | null {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onMenuIconClick = (e: any) => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <div className="items-stretch hidden md:flex">
        {mainMenu && mainMenu.length ? (
          <ul className="h-full flex items-center">
            {mainMenu.map((item: IMenuItem, index: number) => {
              return (<>
                <div className={`${styles.wrapper} pr-2 h-full relative flex items-center border-r`} key={index + item.title}>
                  <Link onClick={onMenuIconClick} to={item.link}>
                    <li className="text-sm px-4 font-bold">{item.title}</li>
                  </Link>

                  {item.items && item.items.length ? <>
                    <div className={`${styles.container} absolute w-48 `} style={{ top: "100%" }}>
                      <div className="bg-white text-black mt-4   shadow-lg w-full">
                        {item.items.map((item: IMenuItem, index: number) =>
                          <div className="p-4 hover:bg-gray-100">
                            <Link onClick={onMenuIconClick} className="" key={index + "" + index + item.title} to={item.link}>
                              <li className="text-sm px-4 font-bold">{item.title}</li>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </> : ""}
                </div>
                {index < mainMenu.length - 1 ?
                  <div className={`${styles.wrapper} px-2 relative`} key={index + item.title + "_"}>

                  </div> : ""}
              </>);
            })}
          </ul>
        ) : (
          ""
        )}
      </div>
      <div className="md:hidden  flex items-center">
        <div onClick={onMenuIconClick} className="bg-white p-4 rounded-md cursor-pointer flex items-center">
          <img src={menuIcon} />
        </div>
        {isMenuOpen ? (
          <div className="">
            <div
              className="absolute shadow-lg rounded-lg left-0 right-0 bg-white p-4"
              style={{ top: "100px" }}
            >
              {mainMenu && mainMenu.length ? (
                <>
                  <ul>
                    {mainMenu.map((item: IMenuItem, index: number) => (
                      <>

                        <Link onClick={onMenuIconClick} to={item.link} key={item.title + index}>
                          <li className="cursor-pointer p-4 font-bold text-lg hover:bg-gray-100">
                            {item.title}
                          </li>
                        </Link>
                        {item.items && item.items.length ? <>
                          {item.items.map((item: IMenuItem, index: number) =>
                            <div className="p-4 hover:bg-gray-100">
                              <Link onClick={onMenuIconClick} className="" key={index + "" + index + item.title} to={item.link}>
                                <li className="px-4">{item.title}</li>
                              </Link>
                            </div>
                          )}
                        </> : ""}
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
