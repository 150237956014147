import { Link } from "gatsby";
import React, { ReactElement } from "react";
import Button from "../../components/button/index";
import logo from "../../images/logo-footer.svg";

export interface FooterProps { menu: any }

export default function Footer({ menu }: FooterProps): ReactElement | null {
  const [cols, setCols] = React.useState([]);
  const colCount = 4;
  React.useEffect(() => {
    const _cols = Array(Math.ceil(menu.length / colCount)).fill(1);
    setCols(_cols);
  }, [menu])

  return (
    <>
      <div className="w-full text-gray-300" style={{ backgroundColor: "#160f42" }}>

        <div className="-max-w-1366 text-gray-400  mx-auto p-4 text-justify">
          <h4 className="text-center mb-4">
            Disclaimer :
          </h4>
          loan-tree.co.nz is not a lender; we do not make lending choices or have any control over lenders' decisions. We take pride in offering people wishing to borrow money a free, no-obligation service. We link people like you with reliable, trustworthy lenders who are eager to lend to them.<br/><br/>
          
          Example Loan: If you borrow $8,000 for 36 months at a rate of 15.95 percent per annum, you will pay $11,640.94 in total fees and interest. We do not offer short-term loans, and all durations range from 12 to 84 months. Our current interest rates range from 7.95 percent APR to 25.95 percent APR (Annual Percentage Rate). Your rate will be determined by your credit history, security provided, and overall credit profile.
          <br /><br/>
          1 All approvals are contingent on the credit criteria and responsible lending requirements of the lender. Your personal circumstances, the type of lending requested, and the security (if any) given will all influence the loan amount and interest rate issued. Fees, terms, and conditions of the lender apply.
          <br /><br/>
          2 The calculator's results are based on the minimal financial information you provided and should only be used as a guide. It is neither an approval, a pre-approval, nor a quotation. The computation is based on amortised scheduled repayments with a fixed interest rate for the length of the loan and utilises an example rate of 9.95 percent p.a.
          <br /><br/>
          3 Our rates range from 7.95% to 25.95% with a maximum term of 5 years.
        </div>
        <div className="p-8">
          <h6 className="text-gray-200 text-center">Sitemap</h6>
          {menu && menu.length ?
            <div className="pt-8 flex  flex-col justify-center items-center">
              {cols.map((c: any, ci: number) =>
                <div className="px-2 flex  flex-col md:flex-row justify-center items-center">
                  {menu.filter((m: any, index: number) => (index >= (colCount * (ci)) && index < colCount * (ci + 1))).map((m: any) =>
                    <Link to={m.link} className="px-2 py-2">{m.title}</Link>
                  )}
                </div>
              )}
            </div> : ""}
        </div>
      </div>
    </>
  );
}
