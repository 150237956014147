import React, { ReactElement } from "react";
import MainMenu, { IMenuItem } from "../main-menu";
import logoFooter from "../../images/logo.svg";
import logo from "../../images/logo.svg";
import { Link } from "gatsby";
const styles = require("./index.module.css");
export interface HeaderProps {
  mainMenu: IMenuItem[];
  safeAreaPadding?: number;
  animated?: boolean;
}

export default function Header(props: HeaderProps): ReactElement | null {
  const bgRef = React.useRef<HTMLDivElement>();
  const wrapperRef = React.useRef<HTMLDivElement>();
  const logoRef = React.useRef<HTMLImageElement>();

  let tid = -1 as any;
  const handleAnim = (e) => {
    const { scrollingElement } = e.target as Document;
    if (scrollingElement) {
      const s = scrollingElement.scrollTop;
      if (s > 0) {
        if (wrapperRef.current) {
          // bgRef.current.style.opacity = (s / 200) + "";
          wrapperRef.current.style.backgroundColor =
            `rgba(255,255,255,1)`;
          if (s > 100) {
            logoRef.current.setAttribute("src", logo)
            // wrapperRef.current.classList.remove('md:text-white');


          } else if (logoRef.current.getAttribute("src") !== logoFooter) {
            logoRef.current.setAttribute("src", logoFooter)
            // wrapperRef.current.classList.add('md:text-white');
          }
          clearTimeout(tid);
          tid = setTimeout(() => {
            if (window.scrollY < 1) {
              logoRef.current.setAttribute("src", logoFooter)
              wrapperRef.current.style.backgroundColor =
                `rgba(255,255,255,1)`;
              // wrapperRef.current.classList.add('md:text-white');
            }
          }, 100)
        }
      }
    }

  }
  React.useEffect(() => {
    if (props.animated) {
      window.addEventListener('scroll', handleAnim);
      return () => { window.removeEventListener('scroll', handleAnim) }
    }
  }, [props.animated]);
  return (
    <>
      <div
        ref={wrapperRef}
        className={`px-8 ${styles.wrapper} ${props.animated ? "" : ""} bg-transparent shadow-lg fixed w-full`}
        style={{
          minHeight: props.safeAreaPadding + "px",
          backgroundColor: `rgba(255,255,255,${props.animated ? '1' : '1'})`,
          zIndex: 1000000
        }}
      >
        <div
          className="flex  relative -max-w-1366 mx-auto h-full items-strech"
          style={{
            minHeight: props.safeAreaPadding + "px",
          }}
        >
          <div className="flex items-center">
            <Link to='/'>
              <img style={{ width: "200px" }} ref={logoRef} src={props.animated ? logoFooter : logo} />
            </Link>
          </div>
          <div className=" flex flex-1 justify-end items-stretch">
            <MainMenu mainMenu={props.mainMenu} />
          </div>
        </div>
      </div>
    </>
  );
}
