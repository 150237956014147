import React from "react";
import Header from "../../hoc/header";
import HelmetContainer from "../../hoc/helmet";
import { useStaticQuery, graphql } from "gatsby";
import Footer from "../../hoc/footer";


interface IDefaultLayoutProps {
  title: string;
  children?: any;
  animatedHeader?: boolean;
}

export default (props: IDefaultLayoutProps) => {
  const safeAreaPadding = 80;
  const data = useStaticQuery(graphql`
    query DefaultLayoutQuery {
      site {
        siteMetadata {
          title
          mainMenu {
            title
            link
            items {
              title
              link
            }
          }
          footerMenu {
            title
            link
          }
        }
      }
    }
  `);
  const { title, mainMenu, footerMenu } = data?.site?.siteMetadata || {};

  return (
    <>
      <HelmetContainer>
        <title>{title} : {props.title}</title>
      </HelmetContainer>
      <Header
        animated={props.animatedHeader}
        safeAreaPadding={safeAreaPadding}
        mainMenu={mainMenu} />
      <div style={{
        // paddingTop: props.animatedHeader ? "" : (safeAreaPadding + "px")
        paddingTop: (safeAreaPadding + "px")
      }}>{props.children}</div>
      <Footer menu={footerMenu} />
    </>
  );
};
